<template>
  <el-scrollbar wrap-class="scrollbar-wrapper" style="height: 100%">
    <div class="main-class">
      <!--条件查询-->
      <el-form :model="queryParams" ref="queryForm" v-show="showSearch" :inline="true" label-width="68px">
        <el-form-item label="影片名称" prop="ip">
          <el-input
              v-model="queryParams.name"
              placeholder="请输影片名称"
              clearable
              auto-complete="off"
              size="small"
              style="width: 200px;"
              @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
      <!--列表-->
      <el-button
          type="success"
          plain
          icon="el-icon-s-promotion"
          size="mini"
          @click="synchronous"
          :loading="bt_loading"
          v-permission="['moban:tage:synchronous']"
      >同步分类
      </el-button>
      <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-permission="['sys:login:delete']"
      >删除
      </el-button>


      <el-table border ref="tables" v-loading="loading" :data="list" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"/>
        <el-table-column label="编号" align="center" width="100" prop="id"/>
        <el-table-column label="名称" align="center" prop="title" :show-overflow-tooltip="true"/>
        <el-table-column label="创建时间" align="center" prop="time"  width="158" :show-overflow-tooltip="true"/>

        <el-table-column
            label="操作"
            align="center"
            width="70"
            class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handleSelect(scope.row)"
                v-permission="['sys:user:update']"
            >查看</el-button>

          </template>
        </el-table-column>

      </el-table>

      <Pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
      />


      <el-drawer
          :title="title"
          size="70%"
          :visible.sync="drawer"
          :direction="direction"
          :close-on-press-escape="false"
          :destroy-on-close="true"
          :wrapper-closable="false"
          :before-close="handleClose">
        <template>
          <div v-html="content"></div>
        </template>
      </el-drawer>
    </div>
  </el-scrollbar>
</template>

<script>
import fa from "element-ui/src/locale/lang/fa";
import tree from "element-ui/packages/tree";

export default {
  name: "Dict",
  computed: {
    tree() {
      return tree
    },
    fa() {
      return fa
    }
  },
  data() {
    return {
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        name,
      },
      title:'',
      content :'',
      // 默认排序
      defaultSort: {prop: 'loginTime', order: 'descending'},
      // 非多个禁用
      multiple: true,
      showSearch: true,
      // 选中数组
      ids: [],
      // 表格数据
      list: [],
      // 总条数
      total: 0,
      loading: false,
      bt_loading:false,
      dateRange: [],
      drawer: false,
      direction: 'rtl',

    }
  },
  methods: {
    getList() {
      this.loading = true
      this.$axios.get("/film/list", {params: {
          name: this.queryParams.name,
          pageNum: this.queryParams.pageNum,
          pageSize: this.queryParams.pageSize
        }}).then(res => {
        this.list = res.data.data.records
        this.total = res.data.data.total
        this.loading = false
      })
    },
    resetForm(formName) {
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() { 
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = 20;
      this.queryParams.name =''
      this.getList();
    },

    /** 同步分类按钮 */
    synchronous(){
      // this.bt_loading = true

      // this.$axios.get("/moban/synchronous").then(res => {
      //   console.log("同步分类按钮数据返回",res.data)
      //   if (res.data.code === 200) {
      //     this.modal.notifySuccess( res.data.msg+ ('成功'+res.data.data.success+'条,失败'+res.data.data.fail))
      //   }
      //   this.bt_loading = false
      // })
    },
    handleClose(){
      this.drawer = false
    },

    handleSelect(row){
      console.log(row)
      this.content = row.content
      this.title = row.title
      this.drawer = true
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const infoIds = row.id || this.ids;
      this.modal.confirm('是否确认删除访问编号为"' + infoIds + '"的数据项？').then(function () {
      }).then(() => {
        this.$axios.delete("/sys/loginInfo?ids=" + infoIds).then(res => {
          if (res.data.code === 200) {
            this.modal.notifySuccess(res.data.data)
          }
          this.getList();
        })
      }).catch(() => {
      });
    },
    /** 多选框选中数据 */
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
    },
  },
  created() {
    this.getList()
  }
}
</script>

